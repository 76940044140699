// Trouver une manière automatique d'exporter la bonne URL selon production ou dev

var URL = `https://apihacktheradio.herokuapp.com/api/v2`
// if (process.env.ENVIRONNEMENT === "development") {
  // var URL = `http://localhost:3000/api/v2`
// }
// else if (process.env.ENVIRONNEMENT === "staging"){
//   var URL = `https://apihacktheradio-staging.herokuapp.com/api/v2`
// }
export default URL
