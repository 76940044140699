import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importHomeDisconnectedSections from '../../actions/pages/import_home_disconnected_sections';
import importHomeDisconnectedSeries from '../../actions/pages/import_home_disconnected_series';

import SliderSection from '../slider_section';
import SliderPlaylist from '../slider_playlist';
import SliderSerie from '../slider_serie';
import PlayButton from '../play_button';
import PlayButtonPresentation from '../play_button_presentation';
import Headband from '../headband';

import renderHTML from '../../helpers/helper_html';

class HomeDisconnected extends Component {

  componentDidMount(){
    this.props.importHomeDisconnectedSections();
    this.props.importHomeDisconnectedSeries();
  }

  exctractSection(position = 0) {
    var section = {}
    if (this.props.sections.length > position) {
      section['id'] = this.props.sections[position].section.id
      section['title'] = this.props.sections[position].section.title_website
      section['style'] = {backgroundImage: `url(${this.props.sections[position].section.photo})`}
      section['description'] = this.props.sections[position].section.description
    }
    return section
  }

  exctractSerie(position = 0) {
    var serie = {}
    if (this.props.series.length > position) {
      serie['id'] = this.props.series[position].serie.id
      serie['title'] = this.props.series[position].serie.name
      serie['style'] = {backgroundImage: `url(${this.props.series[position].serie.photo})`}
      serie['description'] = this.props.series[position].serie.description
    }
    return serie
  }

  render() {
    var first_section = this.exctractSection(0)
    var second_section = this.exctractSection(1)
    var third_section = this.exctractSection(2)

    var first_serie = this.exctractSerie(2)
    var second_serie = this.exctractSerie(1)
    var third_serie = this.exctractSerie(0)

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Découvrir et écouter des podcasts</h2>
            </div>
            <div className="col-12">
              <p>
                L'équipe d'Elson et son comité d'écoute "Oreilles Curieuses" vous partage ses recommandations : 
                Des épisodes unitaires à écouter, des podcasts et des séries à découvrir. Belle écoute !
              </p>
            </div>
          </div>
          <div className="row home__sounds">
            <div className="col-12">
              <h3>Nos épisodes favoris</h3>
            </div>
            <div className={`sounds__podcasts col-12 col-md-4 p-md-4 py-sm-5 ${Object.keys(first_section).length == 0 ? "d-none" : "d-flex flex-column"}`}>
              <div className="order-3" dangerouslySetInnerHTML={renderHTML(first_section['description'])} />
              <div className=" e-card card--title-middle card--title-play order-2 mt-1 mb-2">
                <div className="card__title" style={first_section['style']}>
                  <div className="title__holder">
                    <a className="title__text title__text__home">{first_section['title']}</a>
                    <PlayButton id={first_section['id']} origin={"section"}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={`sounds__podcasts col-12 col-md-4 p-md-4 py-sm-5 ${Object.keys(second_section).length == 0 ? "d-none" : "d-flex flex-column"}`}>
              <div className="order-3" dangerouslySetInnerHTML={renderHTML(second_section['description'])} />
              <div className=" e-card card--title-middle card--title-play order-2 mt-1 mb-2">
                <div className="card__title" style={second_section['style']}>
                  <div className="title__holder">
                    <a className="title__text title__text__home">{second_section['title']}</a>
                    <PlayButton id={second_section['id']} origin={"section"}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={`sounds__podcasts col-12 col-md-4 p-md-4 py-sm-5 ${Object.keys(third_section).length == 0 ? "d-none" : "d-flex flex-column"}`}>
              <div className="order-3" dangerouslySetInnerHTML={renderHTML(third_section['description'])} />
              <div className=" e-card card--title-middle card--title-play order-2 mt-1 mb-2">
                <div className="card__title" style={third_section['style']}>
                  <div className="title__holder">
                    <a className="title__text title__text__home">{third_section['title']}</a>
                    <PlayButton id={third_section['id']} origin={"section"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row home__sounds">
            <div className="col-12">
              <h3> On a aimé des podcasts, on vous en parle !</h3>
            </div>
            <div className={`sounds__voices col-12 col-md-4 p-md-4 py-sm-5 ${Object.keys(first_serie).length == 0 ? "d-none" : "d-flex flex-column"}`}>
              <div className="order-4" dangerouslySetInnerHTML={renderHTML(first_serie['description'])} />
              <div className=" e-card card--title-middle card--title-play order-3 mt-1 mb-2">
                <div className="card__title" style={first_serie['style']}>
                  <div className="title__holder">
                    <a className="title__text title__text__home">{first_serie['title']}</a>
                    <PlayButtonPresentation id={first_serie['id']} origin={"serie"} onHome={true}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={`sounds__voices col-12 col-md-4 p-md-4 py-sm-5 ${Object.keys(second_serie).length == 0 ? "d-none" : "d-flex flex-column"}`}>
              <div className="order-4" dangerouslySetInnerHTML={renderHTML(second_serie['description'])} />
              <div className=" e-card card--title-middle card--title-play order-3 mt-1 mb-2">
                <div className="card__title" style={second_serie['style']}>
                  <div className="title__holder">
                    <a className="title__text title__text__home">{second_serie['title']}</a>
                    <PlayButtonPresentation id={second_serie['id']} origin={"serie"} onHome={true}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={`sounds__voices col-12 col-md-4 p-md-4 py-sm-5 ${Object.keys(third_serie).length == 0 ? "d-none" : "d-flex flex-column"}`}>
              <div className="order-4" dangerouslySetInnerHTML={renderHTML(third_serie['description'])} />
              <div className=" e-card card--title-middle card--title-play order-3 mt-1 mb-2">
                <div className="card__title" style={third_serie['style']}>
                  <div className="title__holder">
                    <a className="title__text title__text__home">{third_serie['title']}</a>
                    <PlayButtonPresentation id={third_serie['id']} origin={"serie"} onHome={true}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    sections: state.sections,
    playlists: state.playlists,
    series: state.series,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(
    {
      importHomeDisconnectedSections,
      importHomeDisconnectedSeries,
    },
    dispatch
  )
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(HomeDisconnected);
